export function getPrivacyPolicyLang() {
    const langs = Object.freeze({
        "en": "en",
        // "zh-hant": "zh-tw",
        // "zh-tw": "zh-tw",
        // "zh-hk": "zh-tw",
        "zh-CN": "zh-cn",
        "zh-cn": "zh-cn",
        "ko": "ko",
        // "tr": "tr",
        // "ja": "ja",
        // "pl": "pl",
        // "ru": "ru",
        // "bs": "bs",
        // "da": "da",
        // 'vi': 'vi',
        // 'fr': 'fr',
        // "fr-FR": "fr",
        // "fr-CA": "fr",
        // "fr-CH": "fr",
        // "es": "es",
        // "pt": "pt",
        // "de": "de",
        // "it": "it",
        // "uk": "uk",
        // "he": "he",
        // "fa": "fa",
        // "az": "az"
    });
    let Lang = navigator.language;
    Lang = Lang.toLowerCase();
    const preLang = Lang.split('-')[0];
    if (preLang !== 'zh') {
        Lang = preLang;
    }
    type LanguageCode = "en" | "zh-CN" | "zh-cn" | 'ko';
    Lang = langs.hasOwnProperty(Lang) ? langs[Lang as LanguageCode] : 'en';
    return Lang;
}

export function getTermsOfServiceLang() {
    const langs = Object.freeze({
        "en": "en",
        // "zh-hant": "zh-tw",
        // "zh-tw": "zh-tw",
        // "zh-hk": "zh-tw",
        "zh-CN": "zh-cn",
        "zh-cn": "zh-cn",
        "ko": "ko",
        // "tr": "tr",
        // "ja": "ja",
        // "pl": "pl",
        // "ru": "ru",
        // "bs": "bs",
        // "da": "da",
        // 'vi': 'vi',
        // 'fr': 'fr',
        // "fr-FR": "fr",
        // "fr-CA": "fr",
        // "fr-CH": "fr",
        // "es": "es",
        // "pt": "pt",
        // "de": "de",
        // "it": "it",
        // "uk": "uk",
        // "he": "he",
        // "fa": "fa",
        // "az": "az"
    });
    let Lang = navigator.language;
    Lang = Lang.toLowerCase();
    const preLang = Lang.split('-')[0];
    if (preLang !== 'zh') {
        Lang = preLang;
    }
    type LanguageCode = "en" | "zh-CN" | "zh-cn" | 'ko';
    Lang = langs.hasOwnProperty(Lang) ? langs[Lang as LanguageCode] : 'en';
    return Lang;
}